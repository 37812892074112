import React,{useState,useContext} from 'react';
import Http from '../../lib/Http';
import Order from '../order/Order';
import AuthContext from '../../context/AuthContext';
import { EnableOrders,EnableIconInMenuWaiter,EnableOrdersClients } from '../../lib/EneablesOrders';
import './DetailItem.css'
import LocalData from '../../lib/LocalData';



export default function DetailItem({ item, fullImage ,setActive}) {
  const [isVisible, setIsVisible] = useState(false);
  const {currentUser} = useContext(AuthContext);
  const userRole = currentUser ? currentUser.role : false ;

  

  const handelOrder = (comments, name, table, nombre_producto, precio, amount, id = item.id) => {
  
    Http.instance.createOrder(comments, name, table ,nombre_producto, precio,amount,currentUser,id)
    setIsVisible(false); 
    setActive(false);
    //TODO  mostrar mensaje de item de pedido realizado
  }

  

  return (
    <div className='d-item'>
      <img className='d-item__image' src={fullImage} alt={item.nombre} />
      <span className='d-item__time'> {item.tiempo}</span>
      <div className="d-item__contain-info">
        <div className="d-item__head">
          <h3 className='d-item__title'>{item.nombre}</h3> 
          {(item.precio !== null   ) && <span className={`d-item__price ${(item.promocion !== null && item.promocion !== 0 ) && 'priceAfter' }`} >{item.precio}</span>}
          {(item.promocion !== null && item.promocion !== 0) && <span className={`list-item__links-price price__promo`}>{item.promocion}</span>}

        </div>
      </div>

      <div className="scroll-h">
        <div className="d-item__description">
          <p>
            {item.descripcion} 
          </p>
        </div>
        
        <div className="d-item__ingredientes">
          {item.ingredientes.map(ingredient => (
            <span key={ingredient} className='d-item__ingredient'>{ingredient}</span>
          ))}
        </div>
        
       
        {
          // Permitir pedidos para clientes
         ( EnableOrders &&  LocalData.instance.getTable() && EnableOrdersClients) &&
            (<div className='d-item__pedir' onClick={()=>{setIsVisible(true)}}>Ordenar</div>)
        }

        { // permitir pedidos para meseros
        ( EnableOrders && EnableIconInMenuWaiter && userRole ) &&
          (<div className='d-item__pedir' onClick={()=>{setIsVisible(true)}}>Ordenar</div>)
        }
    
        
        

        {isVisible && (<Order setIsVisible={setIsVisible} item={item} handelOrder={handelOrder} />)}


      </div>
    </div>
  )
}
