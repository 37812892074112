import {useContext} from 'react';
import Axios from 'axios';
import LocalData from "./LocalData.js";
import Cookies from 'universal-cookie';
import { maxNumberTables } from './EneablesOrders.js';


const dev = true;
export const URL_BASE =  'http://menu.encantorestaurante.com' ;
const TOKEN_KEY = 'directus-encanto-session';
const PROJECT = 'encanto';
const KEY_CURRENT_USER = 'currentUser';
const cookies = new Cookies();





export default class Http {
  static instance = new Http();

  
  axiosInterceptor = () => {

    Axios.interceptors.request.use(function (config) {
      const token = cookies.get('directus-encanto-session');
      if (token) {
        config.headers.Authorization = `bearer ${token}`
      }  
      return config
    })
  }

  logoutReset = (e) => {
     if (e.message  === "Request failed with status code 403") {
       sessionStorage.removeItem('user_encanto');
       cookies.remove('currentUser', { path: '/' });
       cookies.remove(TOKEN_KEY, { path: '/' });
     } 
  }

  getGeneralInfo = async(nameProject) => {
    try {
       
      const filter = `?fields=name,telefono,vence,color_primario,color_de_texto,tipo_de_plan,
      categorias_en_el_home.categoria,slide.id`;      
      const uriDev = `${URL_BASE}${nameProject}/public/${nameProject}/items/general_info${filter}`;
      const uriProduction = `${URL_BASE}/public/${nameProject}/items/general_info${filter}`;
      
      const uri = dev ? uriDev : uriProduction;

      const res = await Axios.get(uri);
      
      return res
    } catch (e) {
      console.log(`Ocurrio un error en la solicitud a ${nameProject}`)
      console.log('Error Http.intance.getGeneralInfo', e)
      return false
    }
  }

  getAllMenu = async() => {
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/menu?fields=mini_descripcion,descripcion,id,nombre,destacado,precio,promocion,status,tiempo,categoria.categoria,subcategoria.subcategoria,subcategoria.icono,categoria.id,foto.data,ingredientes`; 
      const res = await Axios.get(uri);
      return res
    } catch (e) {
      console.log('Ocurrio un error en la solicitud getAllPlates')
      console.log(e)
      return false
    }
  }

  getMenuSync = () => {
    const uri = `${URL_BASE}/public/${PROJECT}/items/menu?fields=mini_descripcion,descripcion,id,nombre,destacado,precio,promocion,status,tiempo,categoria.categoria,subcategoria.subcategoria,subcategoria.icono,categoria.id,foto.data,ingredientes`; 
    Axios.get(uri)
      .then(res => {
        console.log(res)
        return res;
      })
    .catch(e => console.log(e))
  }

  getCategories = async() => {
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/categorias?fields=categoria,id,status,imagen.*.*`;
      const res = await Axios.get(uri);
      return res
    } catch (e) {
      console.log('Ocurrio un error en la solicitud getCategories')
      console.log(e)
      return false
    }
  }

 

  createOrder = async(comments, name, table ,nombre_producto, precio,amount,currentUser,id)=> {
    if (table > maxNumberTables) {
      return alert('Mesa invalida');
    }
    
    if (table ) {
        Axios({
        method: 'post',
        url: `${URL_BASE}/public/${PROJECT}/items/pedidos`,
        data: {
          mesa: table,
          propietario: name || "anonimo",
          id_producto: id,
          comentarios: comments,
          nombre_producto: nombre_producto,
          precio: precio,
          cantidad: amount,
          mesero: currentUser.email
          }
        }).then(res => {
          return res
        }).catch(e=> console.log(e));  
    } else {
      alert('Por favor seleccione una mesa')
    }

  }

  getOrder = async (table) => {
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/pedidos?filter[mesa]=${table}`;
      const res = await Axios.get(uri);
      return res;

    } catch (e) {
      console.log('Ocurrio un error en la solicitud getOrder')
      console.log(e)
      return false
    }
  }

  getOrderToKitchen = async () => {
    this.axiosInterceptor();
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/pedidos?status=published&entregado=false&sort=created_on`;
      const res = await Axios.get(uri);
      return res;

    } catch (e) {
      console.log('Ocurrio un error en la solicitud getOrderToKitchen')
      console.log(e)
      return false
    }
  }

  updategetOrderDelivered = async (id, status) => { 
    this.axiosInterceptor();
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/pedidos/${id}`;
      let response = await Axios({
        method: 'patch',
        url: uri,
        data: {
          entregado: status
        }
      })
      return response
    } catch (error) {
      console.error(error);
      this.logoutReset(error);
      return {error: error, status: 403}
    }

  }

  approveOrder = async (id) => {
    this.axiosInterceptor();
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/pedidos/${id}`;
      let response = await Axios({
        method: 'patch',
        url: uri,
        data: {
          status: 'published'
        }
      })

      return response
    } catch (error) {
      console.error(error);
      this.logoutReset(error);
      return {error: error, status: 403}
    }
  }

  deleteOrder = async (id) => {
    this.axiosInterceptor();
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/pedidos/${id}`;
      const res = await Axios({
      method: 'delete',
      url: uri
      })
      return res;
    } catch (error) {
      console.log('Ocurrio un error en la solicitud deleteOrder')
      this.logoutReset(error);
      return {error: error, status: 403}
    }
  }

  payOutOrder = async (id) => {
    this.axiosInterceptor();
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/pedidos/${id}`;
      const res = await Axios({
      method: 'delete',
      url: uri
      })
      return res;
    } catch (error) {
      console.log('Ocurrio un error en la solicitud payOutOrder')
      this.logoutReset(error);
      return {error: error, status: 403}
    }
  }

  saveBill = async (pedido, table, total, waiter) => {
    this.axiosInterceptor();
    try {
      const uri = `${URL_BASE}/public/${PROJECT}/items/caja`;
      const res = await Axios({
      method: 'post',
        url: uri,
        data: {
          pedido: pedido,
          mesa: table,
          total: total,
          mesero: waiter
      }
      })
      return res;
    } catch (error) {
      console.log('Ocurrio un error en la solicitud payOutOrder')
      this.logoutReset(error);
      return {error: error, status: 403}
    }
  }



  // Auth Serctions
  loginWithEmail = async (email, password) => {
    
    this.axiosInterceptor();
    const uri = `${URL_BASE}/public/${PROJECT}/auth/authenticate`;
   
    try {
      
      let res = await Axios({
        method: 'post',
        url: uri,
        data: {
        email: email,
        password: password,
        mode: dev ? "jwt" : "cookie" 
        }
      });
      if (res.data.data.token) {
        cookies.set('directus-encanto-session', res.data.data.token, { path: '/' }) 
        cookies.set('currentUser', res.data.data.user, { path: '/' });
        LocalData.instance.setTable(1);
      }

    return { status: 200, data: res, message: 'Login success' }
  } catch (error) {
    console.log(error);
    return { status: 404 , data: null , message: 'Email o contraseña incorrecta'}
  } 

  }

  signOut = () => {
    sessionStorage.removeItem('user_encanto');
    cookies.remove('currentUser', { path: '/' });
    cookies.remove(TOKEN_KEY, { path: '/' });
 }
 
  resetPassword = async (email) => {
    const uri = `${URL_BASE}/public/${PROJECT}/auth/password/request`;
    try {
      
      let res = await Axios({
        method: 'post',
        url: uri,
        data: {
        email: email,
        reset_url: `${URL_BASE}/new_password`,
        }
      });
      return res
    } catch (error) {
      console.log(error);
      return { status: 404 , data: null , message: 'Restablecimiento de contraseña fallo'}
    } 
  }

  newPassword = async (password, token) => {
    const uri = `${URL_BASE}/public/${PROJECT}/auth/password/reset`;
    try {
      
      let res = await Axios({
        method: 'post',
        url: uri,
        data: {
        token: token,
        password: password,
        }
      });
      return res
    } catch (error) {
      console.log(error);
      return { status: 404 , data: null , message: 'Actualizacion de nueva contraseña fallo'}
    } 
  }


}