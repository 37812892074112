import React,{useEffect,useState,useContext} from 'react'
import Category from '../../components/category/Category';
import Container from '../../components/sectionContainer/SectionContainer';
import { useParams } from "react-router-dom";
import LocalData from '../../lib/LocalData';
import LoadingComponent from '../../components/loading/Loading';
import './Home.css';
import { maxNumberTables } from '../../lib/EneablesOrders';
import SelectTable from '../../components/selectTable/SelectTable';
import AuthContext from '../../context/AuthContext';



export default function Home({ categories, menu }) {
  const {currentUser} = useContext(AuthContext);
  const userRole = currentUser ? currentUser.role : false;
  
  const [tableSelect, setTableSelect] = useState(LocalData.instance.getTable() || 1)
  const [isloading, setIsloading] = useState(false);
  const [numberTables, setNumberTables] = useState([]);
 

  const handelSelectTable = (e) => {
    setIsloading(true);
    console.log(e.target.value);
    setTableSelect(e.target.value);
    LocalData.instance.setTable(e.target.value);
    setIsloading(false);
  }
  

  const { table } = useParams();
  useEffect(() => {
    let numberTable = parseInt(table)
    if (table !== undefined && !isNaN(numberTable) && table < maxNumberTables + 1 ) {
      LocalData.instance.setTable(numberTable)
    }
  }, [])

  useEffect(() => {
    const Tables = [];
      
    for (var i = 0; i < maxNumberTables; i++) {
      Tables.push(<option key={i} value={i + 1}>Mesa { i + 1 }</option>);
    }
    setNumberTables(Tables);
  }, [tableSelect]);
  


  return (
    <Container title="Categorias" userRole={userRole} menu={menu}>

      {
        userRole &&
        <div style={{ marginBottom: '15px' }}>
        <SelectTable
        table={tableSelect}
        handelSelectTable={handelSelectTable}
        numberTables={numberTables}
       />
        </div>
      }


      <div className= {`${categories.length > 0 && 'container-categories'}`}>
          {
            (categories.length > 0 && !isloading) ? 
              categories.map((category, index) => (
                category.status === "published" && <Category key={index} category={category}  />
              ))
            :
            <LoadingComponent />
          }

      </div>
        
        <img className='home__image-fixed' src={process.env.PUBLIC_URL + '/img/hamburguesa.webp'} alt="hamburguesa encanto" />
          
      
    </Container>

    
  )
}
