const KEY_PROJECT = 'Encato_favoritos';
/* const KEY_IP_ADRESS = 'IP_ADRESS'; */
const KEY_TERMS = 'Check_terms';
const KEY_TABLE = 'table';
const KEY_NAME = 'name';
const KEY_CURRENT_USER = 'currentUser';
const TOKEN_KEY = 'Encanto_token';

export default class LocalData { 
  static instance = new LocalData();

  getData = () => {
    let result = window.localStorage.getItem(KEY_PROJECT);
    if (result === null) {
      return false;
    } else {
      return JSON.parse(result);
    }
  }
  
  saveinLocal = (currentData) => {
    let newData = JSON.stringify(currentData);
    window.localStorage.setItem(KEY_PROJECT, newData);
  }

  setData = (data) => {    
    let currentLocalData = this.getData();
    // si hay data en el LS
    if (currentLocalData) {
      // Se repite el favorito ?
      let duplicate = currentLocalData.some(e => e === data)       
      
      if (!duplicate) {
        currentLocalData.push(data);
        this.saveinLocal(currentLocalData);
      }

    } else {
      let createData = []
      createData.push(data);
      this.saveinLocal(createData);
      return true
    }
  }

  deleteOneFavorite = (data) => {
    this.saveinLocal(data );
    return true
  }
  

  getTable = () => {
    
    let result = sessionStorage.getItem(KEY_TABLE);
    if (result === null) {
      return false;
    } else {
      return JSON.parse(result);
    }
  }

  setTable = (number) => {
    sessionStorage.setItem(KEY_TABLE, number)
  }


  getName = () => {
    let result = localStorage.getItem(KEY_NAME);
    if (result === null) {
      return false;
    } else {
      return result;
    }
  }

  setName = (name) => {
    localStorage.setItem(KEY_NAME, name)
  }

  getTerms = () => {
    
    let result = localStorage.getItem(KEY_TERMS);

    if (result === null) {
      return false;
    } else {
      return result;
    }
  }

  setTerms = (terms) => {
    localStorage.setItem(KEY_TERMS, terms)
  }

  setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token)
    
  }

  signOut =  () => {
    sessionStorage.removeItem((KEY_CURRENT_USER))
    localStorage.removeItem(TOKEN_KEY)
    return true
 }
  setCurrentUser = (data) => {
    sessionStorage.setItem(KEY_CURRENT_USER,JSON.stringify(data))
  }

  getCurrentUser = () => {
    let response = sessionStorage.getItem(KEY_CURRENT_USER)
    return JSON.parse(response);
  }




}