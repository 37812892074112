import {createContext,useState} from 'react';
import LocalData from '../lib/LocalData';

const AuthContext = createContext();


const AuthProvider = ({ children }) => {
  

  const [currentUser, setCurrentUser] = useState(null);

  const updateUser = (data) => {
    LocalData.instance.setCurrentUser(data)
    setCurrentUser(data)
  }

  const signOut = () => {
    let res = LocalData.instance.signOut();
    res && setCurrentUser(null)
  }

  const data = {currentUser, setCurrentUser,updateUser,signOut}
  return<AuthContext.Provider value={data}>{ children}</AuthContext.Provider>
}

export { AuthProvider };
export default AuthContext;