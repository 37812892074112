import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import './Header.css';



const loginIcon = process.env.PUBLIC_URL + '/icons/loginIcon.webp';
const configIcon = process.env.PUBLIC_URL + '/icons/config.webp';


export default function Header() {
    const {currentUser} = useContext(AuthContext);

  return (
    <div id='Header' className='header'>
      <div className="header__container-logo">
        <img src={process.env.PUBLIC_URL + '/img/logo_blanco_sl.png'} alt="" className='header__logo' />
      </div>
      <div className="header--title">
        <h2 className='header--title-text'>Encantanto Restaurante</h2>
      </div>

      {currentUser ? 
        <Link className="button-login"to={'/perfil'}>
          <img src={configIcon} alt="configuiracion Encanto restaurante" />
        </Link>
        :
        <Link className="button-login"to={'/login'}>
          <img src={loginIcon} alt="login Encanto restaurante" />
        </Link>
      }
    </div>
  )
}
