import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { EnableIconInMenu } from '../../lib/EneablesOrders';
import { MdDeliveryDining } from "react-icons/md"; 
import './MenuMobile.css';


export default function MenuMobile({ table }) {
  const {currentUser} = useContext(AuthContext);
  const userRole = currentUser ? currentUser.role : false ;

  let urlBase = process.env.PUBLIC_URL;
  const navigate = useNavigate();


  return (
    <div id='menu-movil' className='menu-movil'>

      <div className="menu-movil--button">
        <Link to='/'>
          <img className='menu-movil--icon' src={urlBase + '/icons/home.png'} alt="" />
        </Link>
      </div>

  
      <div className="menu-movil--button">
        <Link to='/favoritos'>
           <img className='menu-movil--icon' src={urlBase + '/icons/favorites.png'} alt="" />
        </Link>
      </div>

      {
        !userRole  && 
        <div className="menu-movil--button">
          <Link to='/domicilios' style={{ textDecoration: 'none', color: 'black' }}>
            <MdDeliveryDining  size={30}/>
          </Link>
        </div>
      }
      
      
      {/* Rol Mesero */}
      {
        (userRole === '3' ) &&
        <div className="menu-movil--button">
          <Link to='/mesas'>
              <img className='menu-movil--icon'
                src={ urlBase + '/icons/table.png' }
                alt="" />
          </Link>
        </div>
      }

      {
        (userRole === '4') &&
        <div className="menu-movil--button">
          <Link to='/caja'>
              <img className='menu-movil--icon'
                src={ urlBase + '/icons/cash.webp'  }
                alt="" />
          </Link>
        </div>
      }
      

      {/* cocina */}
       {userRole === '5'   && currentUser &&
         <div className="menu-movil--button">
          <Link to='/cocina'>
            <img className='menu-movil--icon' src={urlBase + '/icons/cocina.webp'} alt="" />
          </Link>
        </div>
      }


      {
        EnableIconInMenu && table  &&  !currentUser && 
      (<div className="menu-movil--button">
        <Link to='/mesa'>
          <img className='menu-movil--icon' src={urlBase + '/icons/table.png'} alt="" />
        </Link>
      </div>)
      }

      

      

      {currentUser &&
        <div className="menu-movil--button">
        <span onClick={() => navigate('/perfil')}>
          <img className='menu-movil--icon' style={{width: '20px'}} src={urlBase + '/icons/user.webp'} alt="" />
        </span>
        </div>
      }
      
      <div className="menu-movil--button">
        <span onClick={() => navigate(-1)}>
          <img className='menu-movil--icon' src={urlBase + '/icons/back.png'} alt="" />
        </span>
      </div>

    </div>
  )
}
