import React from 'react'
import { Link } from 'react-router-dom';
import './Category.css';



const defaultImage = process.env.PUBLIC_URL + '/img/sin_imagen.webp';

export default function Category({ category }) {
  const imagenUrl = category.imagen === null ? defaultImage : category.imagen.data.thumbnails[0].url; 
  
  return (
    <Link to={"/lista/" + category.categoria} style={{   textDecoration: 'none', display: 'flex'}} className='category' >

          <img className='category--icon' src={imagenUrl} alt={category.categoria}  />
          
          <h4 className="category--title">
            {category.categoria}
          </h4>
   
    </Link>
  )
}
