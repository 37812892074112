import React from 'react';
import './Loading.css';

export default function Loading() {
  return (
    <div className="loading-container">
      <div className='loading-container__image'>
        <img src={process.env.PUBLIC_URL + '/img/logo_blanco_sl.png'} alt="" />
      </div>

    </div>
  );
}
