import React,{ useState,useContext} from 'react';
import './Search.css'
import { FiShoppingBag,FiEye } from "react-icons/fi";
import { IconContext } from "react-icons";
import Modal from '../modal/Modal';
import DetailItem from '../detailItem/DetailItem';
import Order from '../order/Order';
import Http from '../../lib/Http';
import AuthContext from '../../context/AuthContext';

const baseUrl = process.env.PUBLIC_URL;
const defaultImage = baseUrl + '/img/sin_imagen_full.webp';

export default function Search({ menu,userRole }) {
  
  
  const [filterMenu, setFilterMenu] = useState([]);
  const [itemSelect, setItemSelect] = useState({});
  const [active, setActive] = useState(false);
  const [fullImage, setFullImage] = useState(defaultImage);
  const [isVisibleOrder, setIsVisibleOrder] = useState(false);
  const {currentUser} = useContext(AuthContext);

  

  const toggle = () => {
    setActive(!active);
  }
  
  const handelViewItem = (item) => {
    setItemSelect(item);
    item.foto === null ? setFullImage(defaultImage) : setFullImage(item.foto.data.thumbnails[5].url); 
    setActive(true);
  }


  const handelFilter = (e) => {
    const { value } = e.target;
    let filtered = menu.filter(item =>   item.nombre.toLowerCase().includes(value.toLowerCase()) )
    if (value.length > 1 ) {
      setFilterMenu(filtered);
    } else {
      setFilterMenu([]);
    }
  }

  const handelOrder = (comments, name, table ,nombre_producto, precio, amount ) => {
    setIsVisibleOrder(false);
    setActive(false);
    Http.instance.createOrder(comments, name, table ,nombre_producto, precio,amount,currentUser,itemSelect.id,)
    //TODO  mostrar mensaje de item de pedido realizado
  }

 
  const handelShowOrder = (item) => {
    setItemSelect(item);
    setIsVisibleOrder(true);
  }

  return (
    <div className="search__container">
      <input
        type="text"
        className='search__input'
        autoFocus={true}
        placeholder='Busca tu plato'
        onChange={handelFilter}
      />
        
      <div className="search__list-item">
        {(menu !== undefined && filterMenu.length > 0) && filterMenu.map(item => (
          <div className="search__item" key={item.id}>
            <img className="search__image" src={item.foto !== null ? item.foto.data.thumbnails[1].url : defaultImage} />
            <div className="search__info">
              <h3 className='global-text-white'> {item.nombre}</h3>
              <IconContext.Provider value={{   size: '22px' }}>
                <div className="search__buttons">
                  <div className="global-text-white" onClick={()=> handelViewItem(item) }>
                    <FiEye  />
                  </div>
                  {userRole && <div className="global-text-white" onClick={() => {handelShowOrder(item)}}>
                    <FiShoppingBag />
                  </div> }
                </div>
              </IconContext.Provider>
            </div>
          </div>
        ))}

      </div>

      <Modal active={active} toggle={toggle}>
        <DetailItem fullImage={fullImage} item={itemSelect} setActive={setActive}  />
      </Modal>
      
      {isVisibleOrder && (<Order setIsVisible={setIsVisibleOrder} item={itemSelect} handelOrder={handelOrder} />)}

    </div>
  );
}
