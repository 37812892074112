import React from 'react';
import Portal from '../Portal';
import './Modal.css';

export default function Modal({ children, toggle, active }) {
  
  return (
    <Portal>
      {active &&
        <div className='modal' >
          <div className="modal__window">
            {children}

            
            <div className='modal__closed' onClick={toggle} ><span>x</span></div> 

          </div>
          <div className="modal__backgroud" onClick={toggle}>

          </div>
        </div>
      }
    </Portal>
  )
}


