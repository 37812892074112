import React from 'react';
import './SelectTable.css'

export default function SelectTable({table,handelSelectTable,numberTables}) {
  return (

  <div className="waiter-select-table">
    <h3 className='waiter-select-table__title'>Seleccione una Mesa</h3>
    <select value={table} className='waiter-custom-select' onChange={handelSelectTable}>
      {numberTables.map(item => (
          item
        ) )}
    </select>
  </div>
  );
}
