import React,{useState,useEffect,useContext} from 'react';
import { Link } from 'react-router-dom';
import LocalData from '../../lib/LocalData';
import AuthContext from '../../context/AuthContext';
import './Order.css';


export default function Order({ setIsVisible,handelOrder ,item }) {

  const {currentUser} = useContext(AuthContext);
  const userRole = currentUser ? currentUser.role : false;
  
  const [name, setName] = useState('');	
  const [isChecked, setIsChecked] = React.useState(false);
  const [comments, setComments] = useState('');
  const [table, setTable] = useState('');
  const [amount, setAmount] = useState(1);

  //validar el numero de mesa de LS
  useEffect(() => {
    let response = LocalData.instance.getTable()
    setTable( response)
  }, [])

  //validar nombre de quien ordena
  useEffect(() => {
    let myName = LocalData.instance.getName();
    if (myName !== null && myName !== false) {
      setName(myName)
    } 
  }, [])


  useEffect(() => {
    let termsValidate = LocalData.instance.getTerms();
    if (termsValidate !== null) {
      var isTrueSet = (termsValidate === 'true');
      isTrueSet ? setIsChecked(true) : setIsChecked(false);
    } 
  }, []);


  

  //update table number
   const handelTable = (e) => {
    let value = e.target.value;
     setTable(value);
     LocalData.instance.setTable(value)
  }


  //update name
  const handelName = (e) => {
    let value = e.target.value;
    setName(value);
    LocalData.instance.setName(value)
  }

  //update check terms
  const handelCheck = () => {
    setIsChecked(!isChecked);
    LocalData.instance.setTerms(!isChecked)
  }

  const handelAmount = (e) => {
    let value = e.target.value;
    setAmount(value);
  }
  
  const handelComment = (e) => {
    setComments(e.target.value);
  }

  return (
    <div className='order-container'>
      <div className="order-window">
        <p className='global-text-white' style={{textAlign: 'center'}}>{ item.nombre}</p>
        <div className="order-window__comments">
          <div className="order-window__container-inputs">
            <input
              value={name}
              onChange={handelName}
              type="text"
              name="my_name"
              id="my_name"
              className='order-window__input order-window__name'
              placeholder='Nombre y primera letra de tu apellido' />

            
              
          </div>
          
          <div className="order-window__container-inputs">
            {userRole &&
              <div >
                <label className='global-text-white'>
                  Cantidad:
                </label>
                <input
                
                onChange={handelAmount}
                type="number"
                name="my_amount"
                id="my_amount"
                className='order-window__input order-window__table'
                placeholder=' 1 '
                disabled={!userRole}
              />
              </div>
            }

            {userRole &&
              <div >
                <label className='global-text-white'>
                  Mesa:
                </label>
                <input
                value={table}
                onChange={handelTable}
                type="number"
                name="my_number_table"
                id="my_table"
                className='order-window__input order-window__table'
                placeholder='# Mesa '
                disabled={!userRole}
              />
              </div>
            }
              
          </div>
          <br />
          <textarea
            value={comments}
            name="Comentarios"
            id="order-window__comments-text"
            cols="40"
            rows="3"
            placeholder='¿Algún comentario? Ej: Sin cebolla y sin tomate... etc'
            onChange={handelComment}
          >
            
          </textarea>
        </div>
        <div className="order-window__term">
         
          <input
            checked={isChecked || false}
            type="checkbox"
            name="term"
            id="term__order"
            className='order-window__term__check'
            onChange={handelCheck}
          />
          <span>Estoy de acuerdo con los <Link className='link__term-text' to="/terminos">terminos y condiciones</Link></span>
        </div>
        {
          !isChecked && 
        (<div className="order-window__alert" >
          <span>Debes aceptar los termino y condiciones para poder realizar una orden por este medio.</span>
        </div>)
        }
        <div className="order-window__buttons">
          <div className="order-window__btn" onClick={()=> {setIsVisible(false)}}>
            <span >Cancelar</span>
          </div>
          <div  className={`order-window__btn btn__orange ${isChecked || 'hiddenElement'}`} onClick={()=>{handelOrder(comments, name, table , item.nombre , item.precio, amount)}}>
            <span>Ordenar</span>
          </div>
        </div>
      </div>
    </div>
  )
}
