import React,{useState} from 'react'
import Modal from '../modal/Modal';
import Search from '../search/Search';
import './SectionContainer.css'



const searchIcon = process.env.PUBLIC_URL + '/icons/search.png';

export default function SectionContainer({ title, children, userRole,menu }) {
   const [active, setActive] = useState(false);


  const toggle = () => {
    setActive(!active);
  }


  return (
    <div className='section-container'>
      <div className="section-container__title">
        <h2 className='section-container-text'>{title}</h2>
      </div>
        {title == 'Categorias' && <img src={searchIcon} alt="seacrh" className='icon-search' onClick={toggle}/>}
      
      <div className="section-container__children">
        {children}
      </div>
      
      <Modal toggle={toggle} active={active} >
        <Search menu={menu} userRole={userRole}/>
      </Modal>

    </div>
  )
}
