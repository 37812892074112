
import React,{Suspense, useContext} from 'react'
import {useEffect,useState} from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './views/home/Home';

import Http from './lib/Http';
import Header from './components/header/Header';
import MenuMobile from './components/menuMobile/MenuMobile';
import Loading from './components/loading/Loading';
import LocalData from './lib/LocalData';

import './App.css';
import AuthContext from './context/AuthContext';





const ListCategory = React.lazy(() => import("./views/lista/ListCategory"));
const Favorites = React.lazy(() => import("./views/favorites/UserDashboard"));
const Table = React.lazy(() => import("./views/table/Table"));
const Terms = React.lazy(() => import("./views/term/Terms"));
const Login = React.lazy(() => import("./views/Login/Login"));
const Profile = React.lazy(() => import("./views/Profile/Profile"));
const TableWaiter = React.lazy(() => import("./views/tableWaiter/TableWaiter"));
const Cashier = React.lazy(() => import("./views/tablesCashier/Cashier"));
const Kitchen = React.lazy(() => import("./views/tablesKitchen/Kitchen"));
const ResetPassword = React.lazy(() => import("./views/resetPassword/ResetPassword"));
const NewPassword = React.lazy(() => import("./views/newPassword/NewPassword"));
const Domicilios = React.lazy(() => import("./views/domicilios/Domicilios"));


function App() {
   
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingMenu, setLoadingMenu] = useState(true); 
  const [categories, setCategories] = useState([])
  const [menu, setMenu] = useState([]);
  const [table, setTable] = useState(false);
  
  //context del usuario actual
  const { updateUser } = useContext(AuthContext);

  useEffect(() => {
    let response = LocalData.instance.getTable();
    response && setTable(response);
     }, []);
  

  useEffect(() => {
    async function getAllMenu() {
      const res = await Http.instance.getAllMenu();
      //TODO consulta de Menu
      /* console.log('consultando Menu ----');
      console.log(res.data.data); */
      setMenu(res.data.data)
      setLoadingMenu(false)
    }

    async function getAllCategories() {
      const res = await Http.instance.getCategories();
      //TODO consulta de Categorias
      /* console.log('consultando Categorias ------');
      console.log(res.data.data) */
      setCategories(res.data.data)
      setLoadingCategories(false)
    }

    function getUser() {
      let res = LocalData.instance.getCurrentUser()
      /* res && console.log(res);  TODO optener usuario*/ 
      updateUser(res);
    }

    getAllCategories();
    getAllMenu();
    getUser()
  }, [])

    
  return (
    <>
 

      
      <div id='app-container' style={{backgroundImage: 'url(/img/figuras-opacity.webp)' ,zIndex: '-1'}}>
      
      <Header /> 
      
     
      {/*   {loadingMenu && loadingCategories ? <Loading /> : false} */}
        
        <Routes>
          <Route exact path="/" element={<Home categories={categories} menu={menu} />} />
          <Route exact path="/:table" element={<Home categories={categories}  menu={menu}/>} />
          
          <Route exact path="/lista/:categoria"
            element={
              
            <Suspense fallback={<Loading />  }>
                <ListCategory menu={menu} />
            </Suspense>
          } />

          <Route exact path="/favoritos" element={
            <Suspense fallback={<Loading />  }>
              <Favorites menu={menu} />
            </Suspense>
          } />

          <Route exact path="/domicilios" element={
            <Suspense fallback={<Loading />  }>
              <Domicilios />
            </Suspense>
          } />

          

          <Route exact path="/mesa" element={
            <Suspense fallback={<Loading />  }>
              <Table LoadingComponent={Loading} />
            </Suspense>
          } />

          <Route exact path="/mesas" element={
            <Suspense fallback={<Loading />  }>
              <TableWaiter LoadingComponent={Loading} />
            </Suspense>
          } />
          

          <Route exact path="/terminos" element={
            <Suspense fallback={<Loading />  }>
              <Terms />
            </Suspense>
          } />

          <Route exact path="/login" element={
            <Suspense fallback={<Loading />  }>
              <Login />   
            </Suspense>
          } />

          <Route exact path="/resetpassword"  element={
            <Suspense fallback={<Loading />  }>
              <ResetPassword />   
            </Suspense>
          } />

          <Route exact path="/new_password" element={
            <Suspense fallback={<Loading />  }>
              <NewPassword />   
            </Suspense>
          } />

          

          <Route exact path="/perfil" element={
            <Suspense fallback={<Loading />  }>
              <Profile />   
            </Suspense>
          } />

          <Route exact path="/caja" element={
            <Suspense fallback={<Loading />  }>
              <Cashier LoadingComponent={Loading} />
            </Suspense>
          } />

          <Route exact path="/cocina" element={
            <Suspense fallback={<Loading />  }>
              <Kitchen LoadingComponent={Loading} />
            </Suspense>
          } />


          

        </Routes>   
        
        <MenuMobile table={table}/>
  
        </div>
    </>
  );
}

export default App;